import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'transformInnerHTML',
})
export class TransformInnerHTMLPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(content: string): unknown {
    const sanitizedHtml = this.sanitizer.sanitize(
      SecurityContext.HTML,
      content
    );

    const transformToTailwindFormat = sanitizedHtml
      ?.replaceAll('<ol>', '<ol class="list-disc list-inside">')
      .replaceAll('<li>', '<li class="list-item">')
      .replaceAll(/<a href="(?!https?:\/\/)([^"]+)"/g, '<a href="https://$1"')
      .replaceAll('<a href', '<a class="text-main-blue hover:underline" href');

    if (transformToTailwindFormat) {
      return transformToTailwindFormat;
    } else {
      return '';
    }
  }
}
