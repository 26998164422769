import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-main-card',
  templateUrl: './main-card.component.html',
  styleUrls: ['./main-card.component.scss'],
})
export class MainCardComponent {
  @Input() id: string | number = '';
  @Input() name: string = '';
  @Input() nameEN: string = '';
  @Input() title: string = '';
  @Input() titleEN: string = '';
  @Input() logo: string = '';
  @Input() image: string = '';
  @Input() desc: string = '';
  @Input() descEN: string = '';
  @Input() topTag: string = '';
  @Input() category: any = '';
  @Input() code: string | undefined = '';
  @Output() onClick = new EventEmitter<any>();

  subscription!: Subscription;
  lang!: string;

  constructor(private translocoService: TranslocoService) {}

  ngOnInit(): void {
    this.subscription = this.translocoService.langChanges$.subscribe(
      (activeLang) => {
        this.lang = activeLang;
      }
    );
  }

  onClickButton(event: any) {
    this.onClick.emit(event);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
