<div
  (click)="onClickButton($event)"
  class="shadow-md shadow-gray-300 border-2 rounded-2xl w-full md:w-[22rem] cursor-pointer hover:shadow-2xl text-ellipsis overflow-hidden h-full"
>
  <div class="rounded-2xl relative w-full h-60">
    <img
      class="rounded-tl-2xl rounded-tr-2xl w-full h-full object-cover"
      [src]="image"
      alt="offer image"
    />
    <div
      class="left-5 bottom-[-24px] w-14 h-14 rounded-full shadow-md shadow-gray-300 bg-white absolute"
    >
      <img
        class="rounded-full object-contain w-full h-full"
        [src]="logo"
        alt="merchant logo"
      />
    </div>
    <h1
      *ngIf="code"
      class="bg-main-red border text-white px-8 py-1 top-5 right-[-32px] absolute origin-center rotate-45 overflow-hidden"
    >
      {{ "Get Code" | transloco }}
    </h1>

    <!-- <div
      *ngIf="topTag"
      class="absolute bg-white text-xs z-40 p-1.5 top-2 right-2 rounded-lg"
    >
      {{ topTag }}
    </div> -->
    <div
      *ngIf="category"
      class="absolute bg-white text-xs z-40 p-1.5 top-2 left-2 rounded-lg tracking-wider"
    >
      {{ category.toLocaleUpperCase() }}
    </div>
  </div>
  <div class="p-4 pt-10 w-full min-h-30 max-h-36">
    <h1 class="text-lg sm:text-xl line-clamp-2 tracking-tight">
      {{ lang === "th" ? title : titleEN }}
    </h1>
    <span class="text-xs line-clamp-1 mt-1">{{
      lang === "th" ? name : nameEN
    }}</span>
    <p
      class="opacity-50 mt-4 text-sm text-ellipsis overflow-hidden h-10"
      *ngIf="desc || descEN"
    >
      {{ lang === "th" ? desc : descEN }}
    </p>
  </div>
</div>
